import React from "react";

interface DetailsSectionProps {
  title: string;
  listItems: string[];
  variant: "leftAligned" | "rightAligned";
  inverted?: boolean;
  svgPath?: React.ReactNode; // Path to the SVG icon
}

const DetailsSection: React.FC<DetailsSectionProps> = ({
  title,
  listItems,
  variant,
  inverted = false,
  svgPath,
}) => {
  const isLeftAligned = variant === "leftAligned";
  const textStyle = inverted ? "text-white shadow-text" : "text-black";
  const bgColor = inverted ? "bg-black" : "bg-white";

  return (
    <section className={`py-32 ${bgColor}`}>
      <div className={`container mx-auto px-4 flex flex-col lg:flex-row`}>
        {isLeftAligned && (
          <div className="flex-1 flex flex-col gap-32 xl:flex-row xl:gap-4 justify-center items-center">
            <div className={textStyle}>
              <header className="mb-4">
                <h2
                  className={`text-4xl font-bold text-center xl:text-left mb-16 ${
                    inverted ? "gradient-text" : "light-gradient-text"
                  }`}
                >
                  {title}
                </h2>
                {/* <h3 className="text-2xl">{subtitle}</h3> */}{" "}
                {/* Subtitle commented out */}
              </header>
              <article>
                <ul className="list-disc list-inside pl-6">
                  {" "}
                  {/* Increased padding for list */}
                  {listItems.map((item, index) => (
                    <li
                      key={index}
                      className={`mb-8 text-xl ${
                        inverted ? "gradient-text" : "light-gradient-text"
                      }`}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </article>
            </div>
          </div>
        )}

        <div className="flex-1 flex justify-center items-center">{svgPath && svgPath}</div>

        {!isLeftAligned && (
          <div className="flex-1 flex justify-center items-center">
            <div className={textStyle}>
              <header className="mb-4">
                <h2
                  className={`text-4xl font-bold text-center xl:text-left mb-16 ${
                    inverted ? "gradient-text" : "light-gradient-text"
                  }`}
                >
                  {title}
                </h2>
                {/* <h3 className="text-2xl">{subtitle}</h3> */}{" "}
                {/* Subtitle commented out */}
              </header>
              <article>
                <ul className="list-disc list-inside pl-6">
                  {" "}
                  {/* Increased padding for list */}
                  {listItems.map((item, index) => (
                    <li
                      key={index}
                      className={`mb-8 text-xl ${
                        inverted ? "gradient-text" : "light-gradient-text"
                      }`}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </article>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default DetailsSection;
