import React, { useState } from "react";
import axios from "axios";

const ContactSection: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [statusMessage, setStatusMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsError(false);
    setStatusMessage("");
    setIsSending(true);

    // Validate form data
    if (!formData.name || !formData.email || !formData.message) {
      setIsError(true);
      setStatusMessage("Toate câmpurile sunt obligatorii.");
      setIsSending(false);
      return;
    }

    try {
      const response = await axios.post(
        "https://api.orderix.ro/api/app-landing-page/contact",
        formData
      );
      setStatusMessage("Mesajul tău a fost trimis cu succes!");
      setFormData({ name: "", email: "", message: "" }); // Clear form on success
    } catch (error) {
      setIsError(true);
      setStatusMessage("A apărut o eroare la trimiterea mesajului.");
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div id="contact" className="py-16 bg-gray-200">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-4xl font-bold mb-6 light-gradient-text">Contactează-ne</h2>
        <p className="mb-8 light-gradient-text">Ai întrebări? Ne-ar plăcea să auzim de la tine.</p>

        <div className="max-w-md mx-auto">
          <form onSubmit={handleSubmit}>
            {/* Name Input */}
            <input
              type="text"
              name="name"
              placeholder="Numele tău"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-2 text-gray-700 border rounded mb-4"
              disabled={isSending}
            />

            {/* Email Input */}
            <input
              type="email"
              name="email"
              placeholder="Emailul tău"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 text-gray-700 border rounded mb-4"
              disabled={isSending}
            />

            {/* Message Textarea */}
            <textarea
              name="message"
              placeholder="Mesajul tău"
              value={formData.message}
              onChange={handleChange}
              className="w-full p-2 text-gray-700 border rounded mb-4"
              rows={4}
              disabled={isSending}
            ></textarea>

            {/* Submit Button */}
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
              disabled={isSending}
            >
              {isSending ? "Se trimite..." : "Trimite Mesaj"}
            </button>
          </form>

          {/* Status Message */}
          {statusMessage && (
            <div
              className={`mt-4 text-sm ${
                isError ? "text-red-600" : "text-green-600"
              }`}
            >
              {statusMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
