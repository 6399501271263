import React from "react";

const SvgMenuGraphic = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="500"
    height="450"
    viewBox="0 0 668.6 593.48"
  >
    <rect
      x="58.96"
      width="558.67"
      height="426.47"
      rx="21.76"
      ry="21.76"
      fill="#d7d7d8"
      stroke-width="0"
    />
    <path
      d="M80.72,5c-9.24,0-16.76,7.52-16.76,16.76v382.94c0,9.24,7.52,16.76,16.76,16.76h515.15c9.24,0,16.76-7.52,16.76-16.76V21.76c0-9.24-7.52-16.76-16.76-16.76H80.72Z"
      fill="#fff"
      stroke-width="0"
    />
    <rect
      x="336.29"
      y="2.55"
      width="3.99"
      height="421.84"
      fill="#d7d7d8"
      stroke-width="0"
    />
    <circle cx="314.57" cy="65.58" r="5.65" fill="#d7d7d8" stroke-width="0" />
    <circle cx="362.01" cy="65.58" r="5.65" fill="#d7d7d8" stroke-width="0" />
    <circle cx="314.57" cy="139.41" r="5.65" fill="#d7d7d8" stroke-width="0" />
    <circle cx="362.01" cy="139.41" r="5.65" fill="#d7d7d8" stroke-width="0" />
    <circle cx="314.57" cy="213.23" r="5.65" fill="#d7d7d8" stroke-width="0" />
    <circle cx="362.01" cy="213.23" r="5.65" fill="#d7d7d8" stroke-width="0" />
    <circle cx="314.57" cy="287.06" r="5.65" fill="#d7d7d8" stroke-width="0" />
    <circle cx="362.01" cy="287.06" r="5.65" fill="#d7d7d8" stroke-width="0" />
    <circle cx="314.57" cy="360.88" r="5.65" fill="#d7d7d8" stroke-width="0" />
    <circle cx="362.01" cy="360.88" r="5.65" fill="#d7d7d8" stroke-width="0" />
    <path
      d="M315.09,62.97l-1.05-1.7c.26-.16,26.1-15.77,48.54.03l-1.15,1.64c-21.36-15.04-46.1-.12-46.34.03Z"
      fill="#d7d7d8"
      stroke-width="0"
    />
    <path
      d="M315.09,69.9l-1.05-1.7c.26-.16,26.1-15.77,48.54.03l-1.15,1.64c-21.36-15.04-46.1-.12-46.34.03Z"
      fill="#d7d7d8"
      stroke-width="0"
    />
    <path
      d="M315.09,137.12l-1.05-1.7c.26-.16,26.1-15.77,48.54.03l-1.15,1.64c-21.36-15.04-46.1-.12-46.34.03Z"
      fill="#d7d7d8"
      stroke-width="0"
    />
    <path
      d="M315.09,144.06l-1.05-1.7c.26-.16,26.1-15.77,48.54.03l-1.15,1.64c-21.36-15.04-46.1-.12-46.34.03Z"
      fill="#d7d7d8"
      stroke-width="0"
    />
    <path
      d="M315.09,211.27l-1.05-1.7c.26-.16,26.1-15.77,48.54.03l-1.15,1.64c-21.36-15.04-46.1-.12-46.34.03Z"
      fill="#d7d7d8"
      stroke-width="0"
    />
    <path
      d="M315.09,218.21l-1.05-1.7c.26-.16,26.09-15.77,48.54.03l-1.15,1.64c-21.36-15.04-46.1-.12-46.34.03Z"
      fill="#d7d7d8"
      stroke-width="0"
    />
    <path
      d="M315.09,285.42l-1.05-1.7c.26-.16,26.1-15.77,48.54.03l-1.15,1.64c-21.36-15.03-46.1-.12-46.34.03Z"
      fill="#d7d7d8"
      stroke-width="0"
    />
    <path
      d="M315.09,292.36l-1.05-1.7c.26-.16,26.09-15.77,48.54.03l-1.15,1.64c-21.36-15.04-46.1-.12-46.34.03Z"
      fill="#d7d7d8"
      stroke-width="0"
    />
    <path
      d="M315.09,359.57l-1.05-1.7c.26-.16,26.1-15.77,48.54.03l-1.15,1.63c-21.36-15.04-46.1-.12-46.34.03Z"
      fill="#d7d7d8"
      stroke-width="0"
    />
    <path
      d="M315.09,366.51l-1.05-1.7c.26-.16,26.1-15.77,48.54.03l-1.15,1.63c-21.36-15.04-46.1-.12-46.34.03Z"
      fill="#d7d7d8"
      stroke-width="0"
    />
    <path
      d="M464.85,111.77s-20.58,2.32-21.91,34.19c-1.11,26.56-3.37,41.36,7.44,46.9,3.86,1.98,8.4,2.15,12.53.8l44.1-14.5s.66-47.8-14.61-59.42c-15.27-11.62-27.55-7.97-27.55-7.97Z"
      fill="#2f2e43"
      stroke-width="0"
    />
    <polygon
      points="489.16 185.03 466.84 192.37 466.84 160.24 487.09 160.24 489.16 185.03"
      fill="#f3a3a6"
      stroke-width="0"
    />
    <path
      id="uuid-89c22d20-9486-4802-b91b-ddf2a7c7ca0a-46-794"
      d="M420.93,367.41c-1.21,7.36-6.12,12.64-10.96,11.79-4.85-.85-7.79-7.51-6.58-14.87.44-2.95,1.61-5.74,3.4-8.13l5.53-31.12,15.05,3.15-7.05,30.34c.93,2.87,1.14,5.9.61,8.84,0,0,0,0,0,0Z"
      fill="#f3a3a6"
      stroke-width="0"
    />
    <polygon
      points="472.03 574.33 451.13 574.33 439.01 462.64 471.32 462.64 472.03 574.33"
      fill="#f3a3a6"
      stroke-width="0"
    />
    <path
      d="M430.77,592.29c-2.2,0-4.16-.05-5.63-.19-5.55-.51-10.85-4.61-13.51-7-1.19-1.07-1.58-2.79-.96-4.27h0c.45-1.06,1.34-1.85,2.45-2.17l14.67-4.19,23.76-16.03.27.48c.1.18,2.43,4.38,3.21,7.22.3,1.08.22,1.98-.23,2.68-.31.48-.75.76-1.1.92.43.45,1.78,1.36,5.93,2.02,6.07.96,7.33-5.32,7.39-5.58l.04-.21.18-.12c2.88-1.86,4.66-2.7,5.27-2.52.38.11,1.02.31,2.74,17.41.17.54,1.38,4.47.56,8.24-.89,4.1-18.78,2.69-22.36,2.36-.1.01-13.49.96-22.67.96Z"
      fill="#2f2e43"
      stroke-width="0"
    />
    <polygon
      points="519.26 574.33 498.35 574.33 486.23 462.64 518.54 462.64 519.26 574.33"
      fill="#f3a3a6"
      stroke-width="0"
    />
    <path
      d="M478,592.29c-2.2,0-4.16-.05-5.63-.19-5.55-.51-10.85-4.61-13.51-7-1.19-1.07-1.58-2.79-.96-4.27h0c.45-1.06,1.34-1.85,2.45-2.17l14.67-4.19,23.76-16.03.27.48c.1.18,2.43,4.38,3.21,7.22.3,1.08.22,1.98-.23,2.68-.31.48-.75.76-1.1.92.43.45,1.78,1.36,5.93,2.02,6.07.96,7.33-5.32,7.39-5.58l.04-.21.18-.12c2.89-1.86,4.66-2.7,5.27-2.52.38.11,1.02.31,2.74,17.41.17.54,1.38,4.47.56,8.24-.89,4.1-18.78,2.69-22.36,2.36-.1.01-13.49.96-22.67.96Z"
      fill="#2f2e43"
      stroke-width="0"
    />
    <polygon
      points="525.27 299.99 423.7 299.99 460.21 179.82 500.71 179.82 525.27 299.99"
      fill="#33cc66"
      stroke-width="0"
    />
    <path
      d="M466.6,179.84s-25.23-.66-27.88,5.98c-2.66,6.64-33.86,164.65-33.86,164.65h20.58l41.16-170.62Z"
      fill="#33cc66"
      stroke-width="0"
    />
    <circle cx="469.6" cy="146.89" r="22.24" fill="#f3a3a6" stroke-width="0" />
    <path
      d="M463.52,120.07s-12.24,49.7,1.37,72.93l-19.17-4.07s-7.43-54.39,7.51-64.28l10.29-4.58Z"
      fill="#2f2e43"
      stroke-width="0"
    />
    <path
      d="M504.16,299.99h-66.66l-36.47,149.69c-.67,6.32,3.34,12.22,9.48,13.87,20.01,5.36,68.03,12.95,140.7-8.2,6.99-2.03,10.96-9.41,8.77-16.36l-55.82-139Z"
      fill="#2f2e43"
      stroke-width="0"
    />
    <path
      d="M668.6,592.29c0,.66-.53,1.19-1.19,1.19H1.19c-.66,0-1.19-.53-1.19-1.19s.53-1.19,1.19-1.19h666.22c.66,0,1.19.53,1.19,1.19Z"
      fill="#2f2e43"
      stroke-width="0"
    />
    <path
      d="M477.21,179.82l20.73-.91c8.98.68,17.1,5.56,21.91,13.17,14.65,23.16,42.06,71.97,17.59,78.66-32.26,8.81-47.89-40.24-47.89-40.24l-12.34-50.68Z"
      fill="#33cc66"
      stroke-width="0"
    />
    <path
      d="M458.1,197.46l50.76-36.96c.6-.44,1.44-.3,1.88.3l1.23,1.69c.44.6.3,1.44-.3,1.88l-50.75,36.96h-.01s-6.73,2.9-6.73,2.9l-.51-.7,4.43-6.07Z"
      fill="#2f2e43"
      stroke-width="0"
    />
    <path
      id="uuid-98bb229b-1f5d-4c6c-926b-55ef96e77de9-47-795"
      d="M470.1,194.37c-4.26-6.12-4.41-13.33-.35-16.1,4.07-2.77,10.82-.04,15.08,6.08,1.74,2.42,2.84,5.24,3.22,8.2l17.71,26.18-12.99,8.23-16.08-26.68c-2.67-1.41-4.93-3.44-6.59-5.91,0,0,0,0,0,0Z"
      fill="#f3a3a6"
      stroke-width="0"
    />
    <polygon
      points="480.58 215.54 495.21 202.07 524.14 229.01 497.24 247.4 480.58 215.54"
      fill="#33cc66"
      stroke-width="0"
    />
    <path
      d="M110.83,75.9c20.55-.79,41.12-1.13,61.69-1.01s40.8.69,61.17,1.72c11.6.58,23.18,1.31,34.76,2.19,1.93.15,1.92-2.86,0-3-20.51-1.55-41.05-2.64-61.61-3.28s-40.8-.8-61.2-.53c-11.61.16-23.22.46-34.82.91-1.92.07-1.93,3.07,0,3h0Z"
      fill="#33cc66"
      stroke-width="0"
    />
    <path
      d="M110.83,109.84c20.55-.79,41.12-1.13,61.69-1.01s40.8.69,61.17,1.72c11.6.58,23.18,1.31,34.76,2.19,1.93.15,1.92-2.86,0-3-20.51-1.55-41.05-2.64-61.61-3.28s-40.8-.8-61.2-.53c-11.61.16-23.22.46-34.82.91-1.92.07-1.93,3.07,0,3h0Z"
      fill="#2f2e43"
      stroke-width="0"
    />
    <path
      d="M110.83,143.78c16.68-.65,33.37-1.39,50.07-1.68,14.28-.25,28.56-.02,42.82.67,10.17.49,20.33,1.18,30.49,1.94,1.93.15,1.92-2.86,0-3-16.64-1.26-33.3-2.29-49.99-2.61-14.28-.27-28.57.04-42.84.52-10.18.35-20.36.76-30.55,1.16-1.92.07-1.93,3.07,0,3h0Z"
      fill="#2f2e43"
      stroke-width="0"
    />
    <path
      d="M110.83,177.72c17.66-.68,35.33-1.4,53-1.66,15.82-.24,31.65-.03,47.46.69,10.53.48,21.05,1.15,31.57,1.95,1.93.15,1.92-2.86,0-3-17.62-1.33-35.26-2.32-52.92-2.62-15.83-.28-31.66,0-47.48.5-10.54.33-21.08.74-31.62,1.15-1.92.07-1.93,3.07,0,3h0Z"
      fill="#2f2e43"
      stroke-width="0"
    />
    <path
      d="M110.83,211.65c16.06-.62,32.1-1.6,48.15-2.35,13.26-.62,26.52-.83,39.79-.39,9.94.33,19.87.95,29.78,1.7,1.93.15,1.92-2.86,0-3-16-1.21-32.02-2.06-48.07-1.94-13.29.1-26.55.87-39.81,1.58-9.94.53-19.89,1.01-29.84,1.4-1.92.07-1.93,3.08,0,3h0Z"
      fill="#2f2e43"
      stroke-width="0"
    />
    <path
      d="M110.83,245.59c20.55-.79,41.12-1.13,61.69-1.01s40.8.69,61.17,1.72c11.6.58,23.18,1.31,34.76,2.19,1.93.15,1.92-2.86,0-3-20.51-1.55-41.05-2.64-61.61-3.28s-40.8-.8-61.2-.53c-11.61.16-23.22.46-34.82.91-1.92.07-1.93,3.07,0,3h0Z"
      fill="#2f2e43"
      stroke-width="0"
    />
  </svg>
);

export default SvgMenuGraphic;
