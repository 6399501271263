import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faTh,
  faTags,
  faCocktail,
  faCommentDots,
  faStar,
  faReorder
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface FeatureProps {
  icon: IconProp;
  title: string;
  description: string;
  reverse?: boolean;
}

const Feature: React.FC<FeatureProps> = ({
  icon,
  title,
  description,
  reverse,
}) => {
  const featureClass = `flex flex-col ${
    reverse ? "xl:flex-row-reverse" : "xl:flex-row"
  } items-center mb-16 text-center xl:text-left`;

  return (
    <div className={featureClass}>
      <FontAwesomeIcon
        icon={icon}
        className="text-5xl text-blue-500 mb-4 xl:mb-0 xl:mx-6"
      />
      <div className="flex-1">
        <h3 className="text-2xl font-semibold mb-2 light-gradient-text">{title}</h3>
        <p className="text-lg text-gray-600 light-gradient-text ">{description}</p>
      </div>
    </div>
  );
};

const FeaturesSection: React.FC = () => {
  return (
    <div id="features" className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-5xl font-bold text-center mb-20 light-gradient-text">
          Funcționalități Cheie
        </h2>
        <div className="flex flex-col xl:flex-row">
          <div className="flex-1">
            <Feature
              icon={faGlobe}
              title="Meniu Multilingv"
              description="Oferă meniuri în mai multe limbi, deservind o clientelă diversă."
            />
            <Feature
              icon={faTh}
              title="Produse și Categorii Nelimitate"
              description="Gestionează o gamă largă de produse și categorii fără limitări."
            />
            <Feature
              icon={faTags}
              title="Promoții"
              description="Creează și administrează cu ușurință oferte promoționale atractive."
            />
          </div>

          <div className="flex-1 my-8 xl:my-0 xl:mx-8 place-items-center">
            <img
              src="/mockup_1.png"
              // src="/phone_with_hand.png"
              alt="Aplicație pe telefon mobil"
              className="mx-auto"
              style={{width: '70%', maxWidth: "200px"}}
            />
          </div>

          <div className="flex-1">
          <Feature
              icon={faStar}
              title="Comenzi Online"
              description="Ofera posibilitatea clientilor sa faca comenzi chiar din aplicatie."
            />
            <Feature
              icon={faCocktail}
              title="Happy Hour"
              description="Configurează oferte speciale pentru Happy Hour."
            />
            <Feature
              icon={faCommentDots}
              title="Colectare Feedback"
              description="Colectează feedback valoros direct de la utilizatori."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
