import React from "react";
import { scrollToSection } from "../utils";

const Header: React.FC = () => {
  return (
    <nav className="fixed top-0 left-0 w-full z-10 bg-white bg-opacity-90 shadow-md text-gray-800">
      <div className="container mx-auto flex justify-center lg:justify-between p-4 items-center">
        <div className="flex items-center logoSection">
          <a href="#" className="text-xl font-semibold logoSection__a">
            OrderIt
          </a>
          <span className="logoSection__span">by</span>
          <div className="logoSection__logoOrderix">
            <img src="/orderix-logo.svg" alt="Logo" className="h-8 mr-3" />
          </div>
        </div>
        <div className="hidden lg:flex">
          <a
            href="javascript:void(0)"
            onClick={() => scrollToSection("features")}
            className="px-4"
          >
            Funcții
          </a>
          {/* <a href="#pricing" className="px-4">
            Prețuri
          </a> */}
          <a
            href="javascript:void(0)"
            onClick={() => scrollToSection("contact")}
            className="px-4"
          >
            Contact
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Header;
