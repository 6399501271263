import React from "react";
import { scrollToSection } from "../utils";

const HeroSection: React.FC = () => {
  return (
    <div
      className="h-screen bg-cover bg-center"
      style={{
        backgroundImage: "url('/benjamin-zanatta-qk1hk6R9rIQ-unsplash.jpg')",
      }}
    >
      <div className="bg-black bg-opacity-50 h-full flex items-center justify-center">
        <div className="text-center px-6 md:px-12">
          <h1
            className="text-5xl md:text-7xl font-bold text-white mb-6 gradient-text"
            style={{ lineHeight: 1.5 }}
          >
            Descoperă meniul digital
          </h1>
          <p className="text-xl md:text-2xl text-white mb-8 gradient-text">
            Experimentează revoluția în gestionarea produselor si serviciilor
          </p>
          <a
            href="javascript:void(0)"
            onClick={() => scrollToSection("features")}
            className="inline-block bg-blue-500 text-white text-xl px-6 py-3 rounded-full hover:bg-blue-700 transition-colors duration-300"
          >
            Începe acum
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
