import React from "react";

const SvgHappyFeelingsGraphic = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="500"
    height="300"
    viewBox="0 0 840.67004 517.05805"
  >
    <path
      d="M268.19167,184.27551l-5.33077,1.69513c-.06691-.28997-.14498-.5799-.23419-.86987-.69143-2.22487-1.0037,1.7732-2.79922,.28997,.12824,1.91819-2.63191-17.79901-2.89959-15.90311-.11153,.79738,.85316,14.00725,.66914,14.78789l-41.39713,.39588c.23419-3.98691-1.89031-2.45347-3.76946-5.97201-1.10964,3.30664-1.2825,.90891-3.03899,3.90329-.73605,1.26576-1.52227,2.48138-2.36985,3.66906-.40705,.58552-.83642,1.15984-1.27692,1.72862l-.56319,.17844-2.78806-54.646c0-18.01645,15.61314-26.76539,27.88061-15.05553,6.51816,6.22188,22.46064-1.92377,26.13528,3.97578,3.6691,5.90509,5.091,14.05738,5.091,23.06842l6.69135,38.75405Z"
      fill="#2f2e41"
    />
    <g>
      <polygon
        points="202.91102 494.66709 211.89212 494.87903 216.89833 454.53034 203.64449 454.21669 202.91102 494.66709"
        fill="#ffb7b7"
      />
      <path
        d="M233.72401,513.40436h0c0,1.5172-1.07669,2.74715-2.40488,2.74715h-17.82672s-1.75429-7.0518-8.90683-10.08658l-.49365,10.08658h-9.19618l1.11421-16.21877s-2.46002-8.6771,2.64892-13.11258c5.109-4.43548,.9709-3.81803,.9709-3.81803l2.00979-10.03819,13.89642,1.63413,.10218,15.75761,6.74383,15.6409,9.89157,4.88627c.8804,.43489,1.45032,1.4258,1.45032,2.52148l.00011,.00003Z"
        fill="#2f2e41"
      />
    </g>
    <g>
      <polygon
        points="230.23485 488.09175 238.8682 490.57579 253.94936 452.81817 241.20894 449.15152 230.23485 488.09175"
        fill="#ffb7b7"
      />
      <path
        d="M255.28453,514.03476h0c-.385,1.46753-1.73856,2.38401-3.02327,2.04697l-17.24321-4.52368s.09258-7.26615-6.05573-12.01661l-3.03705,9.63116-8.89517-2.33361,5.19339-15.40515s-.17762-9.01732,5.88964-12.01119c6.06731-2.99385,1.90798-3.44669,1.90798-3.44669l4.49128-9.19962,13.02688,5.10697-3.89979,15.26776,2.55408,16.84024,8.32786,7.2364c.74122,.64406,1.04104,1.74716,.763,2.80698l.0001,.00006Z"
        fill="#2f2e41"
      />
    </g>
    <polygon
      points="252.02091 169.52293 224.69791 172.31099 207.41193 271.56597 259.82748 279.93016 252.02091 169.52293"
      fill="#33cc66"
    />
    <path
      d="M199.60536,187.92414l2.78806,64.68302-24.53494,42.93614,17.84359,16.17076s32.89912-65.24063,34.01435-83.08423c1.11522-17.84359-5.29888-54.70896-5.29888-54.70896l-24.81218,14.00327Z"
      fill="#2f2e41"
    />
    <path
      d="M277.67108,187.92414l-2.78806,64.68302,24.53494,42.93614-37.91763,17.28598s-12.82508-66.35586-13.94031-84.19945c-1.11522-17.84359,5.29888-54.70896,5.29888-54.70896l24.81218,14.00327Z"
      fill="#2f2e41"
    />
    <polygon
      points="210.75761 265.43224 197.93252 267.10508 190.68356 344.61318 200.72058 461.96641 217.14161 461.96641 229.15881 331.23049 250.34808 380.30036 234.8447 461.96641 250.34808 463.54705 281.57436 378.62753 259.82748 279.93016 210.75761 265.43224"
      fill="#2f2e41"
    />
    <path
      d="M263.39283,335.40409c.60669-2.13645,1.64563-3.95874,2.88255-5.25866l4.49401-20.71759,9.7957,2.42682-5.6134,20.75464c.36914,1.756,.29529,3.85234-.31141,5.9888-1.38599,4.88072-5.02738,8.12229-8.13328,7.24029-3.1059-.882-4.50015-5.55356-3.11417-10.43427h0Z"
      fill="#ffb6b6"
    />
    <path
      d="M264.74801,183.73423s18.086-.92116,19.4031,11.07227c1.3171,11.99343,5.53024,80.29996,5.53024,80.29996l-9.76619,46.10546-13.92644-1.93524,6.15434-58.68709-7.39506-76.85537Z"
      fill="#2f2e41"
    />
    <path
      d="M109.73504,219.55889c2.05854,.85419,3.75348,2.1025,4.90465,3.48713l20.1243,6.89859-3.56275,9.47992-20.0305-8.01861c-1.79331,.16285-3.8741-.15557-5.93264-1.00976-4.70273-1.95139-7.50834-5.9593-6.26654-8.95193,1.2418-2.99263,6.06074-3.83672,10.76346-1.88534h.00001Z"
      fill="#ffb6b6"
    />
    <path
      d="M224.64825,190.79088l.00002,.00002-45.90965,61.9706-53.81583-14.81885,2.33982-14.03891,40.97876,7.76134,22.86159-35.14608c7.46505-14.4301,22.17835-17.33619,33.5453-5.72811Z"
      fill="#2f2e41"
    />
    <circle cx="236.3463" cy="143.74105" r="20.13551" fill="#ffb7b7" />
    <path
      d="M265.96122,143.0122h-6.78367l-1.84252-2.48317c-.89508,0-1.55448,2.38735-2.41113,2.20514-2.61306-.55581-5.01145-1.91542-6.97643-3.81105-.16597-.16011-.33795-.31398-.51569-.46105l.00296,.04567c.1568,2.45486-1.86797,4.50446-4.32783,4.50446h-13.08144c-3.57144,0-6.17636,3.37962-5.26696,6.83334l-1.17582,42.23654c0-8.6207-38.14069-52.63858-12.26747-70.25914,5.98998-4.07938,10.33812-3.34567,18.95882-3.34567h0c13.2501-7.06675,29.91921-.47198,34.2246,13.91438,.96441,3.22254,1.46258,6.78563,1.46258,10.62055Z"
      fill="#2f2e41"
    />
    <path
      d="M655.52752,143.15152c-.96997-1.02002-1.64996-12.25-2.84998-13,.47998,1.60999,.26001,13.62,.19,15.29999-10.56,7.20001-17.72003-11.29999-25.19-11.29999-13.53003,0-30-30.47003-30-44s17.0445-20.71585,29.5-26c16.5-7,31.5859,8.60133,33.5,22,5,35,.88,50.29999-5.15002,57Z"
      fill="#2f2e41"
    />
    <circle cx="623.13274" cy="95.13151" r="20.13551" fill="#a0616a" />
    <path
      d="M593.83314,90.7602l10.03482,1.24559,2.46706-1.47676,.27426,1.81704c1.41176,.17524,2.8447-.37213,3.69761-1.51068,.68055-.90847,1.51898-1.69835,2.47507-2.30817-.12689,.66217-.22365,1.32917-.28992,1.99814-.1383,1.39599,.84148,2.65538,2.23362,2.82818l14.76955,1.8333c3.54424,.43994,5.71302,4.11469,4.38511,7.43009,0,0,3.58793-7.75774,6.79722-5.46539,7,5,27.1638-5.58817,9.99555-20.68783-5.44185-4.78617-9.84726-4.59366-18.4023-5.65557h0c-12.27869-8.6451-29.63321-4.15388-35.67794,9.59258-1.35402,3.0792-2.2873,6.55379-2.7597,10.35951Z"
      fill="#2f2e41"
    />
    <path
      d="M638.22594,217.20462h-58.7876c4.92749-10.99533,7.95685-21.46701,3.22119-29.79648l55.56641-3.22122c-2.77679,11.24445-3.11621,22.27954,0,33.0177Z"
      fill="#a0616a"
    />
    <path
      d="M630.97814,119.76213l-23.35398-1.61062-9.66372,12.07965-4.89907,.89074c-7.34381,1.33524-12.5732,7.8888-12.24512,15.3458l1.9056,43.31268,55.82064,4.07026,13.3737-56.40211-16.10619-6.41205-4.83186-11.27434Z"
      fill="#e6e6e6"
    />
    <g>
      <polygon
        points="641.12925 474.26024 631.56005 474.48606 626.22602 431.49523 640.34775 431.16105 641.12925 474.26024"
        fill="#a0616a"
      />
      <path
        d="M608.29854,494.22447h0c0,1.61655,1.1472,2.92704,2.56235,2.92704h18.99405s1.86917-7.51357,9.49007-10.74708l.52598,10.74708h9.79837l-1.18718-17.28081s2.62111-9.24529-2.82238-13.97122c-5.44355-4.72593-1.03448-4.06805-1.03448-4.06805l-2.1414-10.69552-14.80639,1.74114-.10887,16.78946-7.18543,16.6651-10.53929,5.20624c-.93805,.46337-1.54529,1.51917-1.54529,2.68659l-.00012,.00003Z"
        fill="#2f2e41"
      />
    </g>
    <g>
      <polygon
        points="606.12925 473.26024 596.56005 473.48606 591.22602 430.49523 605.34775 430.16105 606.12925 473.26024"
        fill="#a0616a"
      />
      <path
        d="M573.29854,493.22447h0c0,1.61655,1.1472,2.92704,2.56235,2.92704h18.99405s1.86917-7.51357,9.49007-10.74708l.52598,10.74708h9.79837l-1.18718-17.28081s2.62111-9.24529-2.82238-13.97122c-5.44355-4.72593-1.03448-4.06805-1.03448-4.06805l-2.1414-10.69552-14.80639,1.74114-.10887,16.78946-7.18543,16.6651-10.53929,5.20624c-.93805,.46337-1.54529,1.51917-1.54529,2.68659l-.00012,.00003Z"
        fill="#2f2e41"
      />
    </g>
    <path
      d="M581.67754,209.15152l-8,26-3,106,20.7891,98.16104,16.02847-2.21082,3.86894-135.41297,9.94871,135.41297,22.55033-.85287s18.81444-196.09735-4.86374-220.22953l-2.13902-9.11965-55.1828,2.25183Z"
      fill="#2f2e41"
    />
    <g>
      <polygon
        points="567.0873 169.02057 534.0962 193.09708 498.87192 206.80341 496.42396 198.48641 525.28713 181.35649 561.13457 153.89068 567.0873 169.02057"
        fill="#a0616a"
      />
      <ellipse
        cx="491.59814"
        cy="205.1791"
        rx="9.63433"
        ry="5.72038"
        transform="translate(-39.8764 241.26243) rotate(-26.53829)"
        fill="#a0616a"
      />
      <path
        d="M588.20507,134.6742s10.2495,14.07175,2.84195,19.60651c-7.40755,5.53476-29.85447,31.50633-29.85447,31.50633l-18.55234-20.40329,45.56485-30.70954Z"
        fill="#e6e6e6"
      />
    </g>
    <g>
      <path
        d="M107.17754,0c-27.89039,0-50.5,22.60961-50.5,50.5,0,23.45518,20.56363,53.99852,39.8464,69.03578,1.73275,1.35125,3.66823,2.29223,5.6854,2.85419l1.89382,6.81771c.39369,1.41743,1.68428,2.39838,3.15536,2.39838h.2399c1.53493,0,2.8639-1.06609,3.19688-2.56445l1.55669-7.00508c1.68372-.59796,3.29491-1.46721,4.76067-2.61503,21.57859-16.89736,40.66488-44.67301,40.66488-68.9215C157.67754,22.60961,135.06793,0,107.17754,0Z"
        fill="#33cc66"
      />
      <path
        d="M115.5942,125.48486c-6.06848,15.20361-10.94904,11.73953-15.30301,0v-3.06062h15.30302v3.06062Z"
        fill="#3f3d56"
      />
      <polygon
        points="108.70784 123.9622 108.36352 152.81604 108.34825 153.79545 108.27936 254.0303 108.27936 258.68239 108.26404 260.15152 106.72608 260.15152 106.7414 258.75893 106.7414 254.0303 106.81794 153.79545 106.9021 146.88611 107.17754 123.94689 108.70784 123.9622"
        fill="#3f3d56"
      />
      <polygon
        points="108.4324 260.15152 106.88678 260.15152 106.7414 258.75893 106.71081 254.0303 106.09104 153.79545 102.93862 123.78619 104.46126 123.62554 106.9021 146.88611 107.629 153.79545 107.93503 254.0303 107.94269 255.50704 108.27936 258.68239 108.4324 260.15152"
        fill="#3f3d56"
      />
      <polygon
        points="112.94677 123.78619 109.79435 153.79545 108.90679 254.0303 108.85323 260.15152 107.45298 260.15152 107.94269 255.50704 107.95035 254.0303 108.25638 153.79545 108.36352 152.81604 111.42412 123.62554 112.94677 123.78619"
        fill="#3f3d56"
      />
    </g>
    <g>
      <path
        d="M750.47097,134.51789c-16.78258-13.62546-41.43315-11.06611-55.05862,5.71647-11.4587,14.11377-14.0064,42.5388-9.74955,61.00754,.38252,1.65961,1.08746,3.17138,2.02672,4.49498l-2.19112,5.02765c-.45557,1.04525-.15821,2.26602,.72699,2.9847l.14436,.1172c.92362,.74987,2.24413,.75761,3.1765,.01867l4.35895-3.45469c1.30527,.46274,2.69944,.72681,4.14219,.75221,21.23953,.37421,46.2938-7.01499,58.14006-21.60612,13.62546-16.78258,11.06611-41.43315-5.71647-55.05862Z"
        fill="#ff6584"
      />
      <path
        d="M694.23168,214.13818c-16.52942,10.90488-15.56281,4.39578-9.20831-7.47607l1.49519-1.84168,9.20835,7.47608-1.49523,1.84167Z"
        fill="#3f3d56"
      />
      <polygon
        points="690.8318 209.85772 676.52847 227.05182 676.0408 227.63371 627.03101 287.91473 624.7583 290.71404 624.03136 291.59058 623.10592 290.83923 623.79546 290.00875 626.10557 287.16338 675.11996 226.8861 678.54606 222.76963 689.91845 209.10089 690.8318 209.85772"
        fill="#3f3d56"
      />
      <polygon
        points="624.13267 291.67283 623.20261 290.91774 623.79546 290.00875 626.08716 287.14843 674.68256 226.53098 687.44625 206.93333 688.44096 207.58052 678.54606 222.76963 675.608 227.28233 626.82382 287.74651 626.10699 288.63885 624.7583 290.71404 624.13267 291.67283"
        fill="#3f3d56"
      />
      <polygon
        points="693.46849 211.82267 676.91097 228.34018 627.40856 288.22125 624.38589 291.87842 623.54332 291.19435 626.10699 288.63885 626.83303 287.75399 675.98552 227.58883 676.52847 227.05182 692.63075 210.98213 693.46849 211.82267"
        fill="#3f3d56"
      />
    </g>
    <g>
      <path
        d="M439.1389,46.1396c-21.44577,10.51596-30.30614,36.42604-19.79018,57.87181,8.84368,18.03541,36.17193,33.76769,56.66877,38.05981,1.84185,.38569,3.68489,.37947,5.44784,.05102l4.02681,4.52829c.83716,.94146,2.19939,1.20914,3.33056,.65447l.18447-.09045c1.18025-.57874,1.80017-1.89957,1.49126-3.17726l-1.44425-5.97336c1.0692-1.09463,1.98035-2.37051,2.67464-3.80577,10.22136-21.12902,14.4247-49.68297,5.28191-68.32838-10.51596-21.44577-36.42604-30.30614-57.87181-19.79018Z"
        fill="#33cc66"
      />
      <path
        d="M492.92425,139.45529c-.32532,8.02927-2.76648,12.46704-11.76697,5.76993l-1.15396-2.35339,11.76697-5.76994,1.15396,2.3534Z"
        fill="#3f3d56"
      />
      <polygon
        points="487.05503 140.88094 497.66948 163.19737 498.02703 163.95623 489.89798 245.64277 491.65204 249.2199 492.19418 250.35533 491.0116 250.93521 490.49831 249.85864 488.7154 246.22265 496.85033 164.53322 494.3099 159.18869 485.87256 141.44616 487.05503 140.88094"
        fill="#3f3d56"
      />
      <polygon
        points="492.32364 250.29185 491.13516 250.87462 490.49831 249.85864 488.69188 246.23418 496.29139 164.8073 482.55253 142.92086 483.66276 142.22323 494.3099 159.18869 497.47398 164.22742 489.63322 245.77259 490.19591 246.90522 491.65204 249.2199 492.32364 250.29185"
        fill="#3f3d56"
      />
      <polygon
        points="490.2481 139.14733 499.13898 163.41098 490.38043 245.4062 492.64722 250.13318 491.57054 250.66114 490.19591 246.90522 489.645 245.76682 497.95639 163.99087 497.66948 163.19737 489.01672 139.59791 490.2481 139.14733"
        fill="#3f3d56"
      />
    </g>
    <path
      d="M0,515.86805c0,.66003,.53003,1.19,1.19006,1.19H404.48004c.65997,0,1.19-.52997,1.19-1.19,0-.65997-.53003-1.19-1.19-1.19H1.19006c-.66003,0-1.19006,.53003-1.19006,1.19Z"
      fill="#ccc"
    />
    <path
      d="M435,495.86805c0,.66003,.53003,1.19,1.19006,1.19h403.28998c.65997,0,1.19-.52997,1.19-1.19,0-.65997-.53003-1.19-1.19-1.19H436.19006c-.66003,0-1.19006,.53003-1.19006,1.19Z"
      fill="#ccc"
    />
    <polygon
      points="659.00413 184.39406 658.47964 225.23296 648.31814 261.63841 640.1951 258.60837 643.80174 225.23908 643.32764 180.08175 659.00413 184.39406"
      fill="#a0616a"
    />
    <ellipse
      cx="642.65549"
      cy="266.4841"
      rx="9.63433"
      ry="5.72038"
      transform="translate(265.36337 851.00999) rotate(-79.68097)"
      fill="#a0616a"
    />
    <path
      d="M646.67348,135.7674s17.40715,.23962,17.39246,9.48652-1.92406,43.6615-1.92406,43.6615l-27.45346,2.60593,11.98506-55.75395Z"
      fill="#e6e6e6"
    />
  </svg>
);

export default SvgHappyFeelingsGraphic;
