import React from "react";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import FeaturesSection from "./components/FeatureSection";
import CallToAction from "./components/CallToAction";
import Footer from "./components/Footer";
import ContactSection from "./components/ContactSection";
import OrderProcessSection from "./components/OrderProcessSection";
import DetailsSection from "./components/DetailsSection";
import SvgMenuGraphic from "./components/SvgMenuGraphic";
import SvgCookingGraphic from "./components/SvgCookingGraphic";
import SvgWinnersGraphic from "./components/SvgWinnersGraphic";
import SvgHappyFeelingsGraphic from "./components/SvgHappyFeelingsGraphic";
import SvgRemindersGraphic from "./components/SvgRemindersGraphic";

function App() {
  return (
    <div className="App">
      <Header />
      <HeroSection />
      <FeaturesSection />
      <DetailsSection
        title="Meniu Multilingv"
        // subtitle="Poti sa iti publici meniul in mai multe limbi"
        listItems={[
          "Ofera descrierile produselor si serviciilor tale in limbile dorite: romana, engleza sau orice alta limba doresti.",
          "Faciliteaza intelegerea ofertei pentru vorbitorii de limbi diferite si imbunatateste experienta clientilor",
          "Clientul acceseaza meniul folosing codul QR expus si alege limba dorita dintre limbile adaugate din interfata de administrare",
        ]}
        variant="leftAligned"
        inverted={true}
        svgPath={<SvgMenuGraphic />}
      />
      <DetailsSection
        title="Produse si categorii nelimitate"
        // subtitle="Poti adauga oricat de multe categorii si produse"
        listItems={[
          "Adauga oricat de multe produse si servicii doresti sa expui. Nu exista un numar limita care poate fi adaugat",
          "Ideal pentru restaurante, magazine sau hoteluri.",
          "Ofera clientilor tai posibilitatea sa exploreze usor gama ta de produse si servicii",
        ]}
        variant="rightAligned"
        svgPath={<SvgCookingGraphic />}
      />
      <DetailsSection
        title="Promotii"
        // subtitle="Intre anumite intervale orare"
        listItems={[
          "Promotiile joaca un rol crucial in influentarea deciziilor de cumparare ale clientilor",
          "Atrage clienti noi sau fidelizeaza clientii existenti prin intermediul promotiilor din cadrul aplicatiei",
          "Prin intermediul aplicatiei poti configura promotii sezoniere sau cu timp limitat, crescand astfel interesul clientilor tai",
        ]}
        variant="leftAligned"
        inverted={true}
        svgPath={<SvgWinnersGraphic />}
      />
      <DetailsSection
        title="Comenzi online"
        // subtitle="Intre anumite intervale orare"
        listItems={[
          "Prin intermediul comenzilor plasate direct din aplicatie, oferi clientilor tai o experienta moderma a cumparaturilor online",
          "Clientii pot explora optiunile si plasa comenzi fara sa fie nevoiti sa astepte un chelner sau operator comercial. Timpii operationali sunt astfel imbunatatiti iar echipa ta poate aloca mai mult timp pentru alte sarcini importante ",
          "Intelege preferințele și comportamentul de cumpărare al clienților tai. Personalizeaza-ti astfel ofertele și promoțiile, crescand vanzarile",
        ]}
        variant="rightAligned"
        svgPath={<SvgHappyFeelingsGraphic />}
      />
      <DetailsSection
        title="Colectare Feedback"
        // subtitle="Intre anumite intervale orare"
        listItems={[
          "Colecteaza feedback-ul de la clienți permițând îmbunătățirea continuă a experientei oferite acestora prin intermediul formularul de feedback pus la dispozitie in aplicatie",
        ]}
        variant="leftAligned"
        inverted={true}
        svgPath={<SvgRemindersGraphic />}
      />
      {/* <OrderProcessSection /> */}
      {/* <PricingSection /> */}
      <ContactSection />
      {/* <CallToAction /> */}
      <Footer />
    </div>
  );
}

export default App;
